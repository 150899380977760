<template>
  <v-overlay
    :value="show"
  >
    <v-card
      width="500"
      light
    >
      <v-toolbar
        color="#333"
        dark
      >
        <div class="text-h5">
          Пересчет весовых параметров
        </div>
      </v-toolbar>
      <v-card-text class="pa-6">
        <div class="mx-2">
          <label>
            Укажите вес {{ params.title }}
            <v-text-field
              v-model="weight"
              outlined
              dense
              hide-details="auto"
              background-color="white"
              :suffix="weight && params.field === 'packageWeight' ? (Number.parseFloat(grossWeightSum) + Number.parseFloat(weight)).toString() + ' кг (брутто с упаковкой)' : ''"
              @input="(v) => (weight = v.replace(',', '.'))"
            /></label>
        </div>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="#333"
          text
          @click="close"
        >
          Отмена
        </v-btn>
        <v-btn
          color="#5CB7B1"
          dark
          @click="submit"
        >
          Пересчитать
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>
<script>
import closeOnEscape from '@/mixins/close-on-escape.mixin.js'
import {normalizeFloat} from '@/helpers/math'
import { mapGetters } from 'vuex'
export default {
  mixins:[closeOnEscape],
  props:{
    show:{
      required:true,
      type:Boolean
    },
    params:{
      required:true,
      type:Object
    }
  },
  data:() => ({
    weight:null
  }),
  computed:{
    ...mapGetters({
      selected:'statistics/getSelected',
      wares:'statistics/getSelectedWares'
    }),
    netWeightSum(){
      return this.getTotal('net_weight')
    },
    netWeightNoPackSum(){
      return this.getTotal('net_weight_no_pack')
    },
    grossWeightSum(){
      return this.getTotal('gross_weight')
    },
  },
  watch:{
    show(nv){
      if(nv === false){
        this.unset()
      }
    }
  },
  beforeDestroy(){
    this.unset()
  },
  methods:{
    getTotal(field){
      if(this.wares){
        const value = this.wares.reduce((acc, current) => {
          return acc + (current[field] || 0)
        }, 0)
        return normalizeFloat({value})
      }
      return ""
    },
    getPackageSuffix(){
      return this.params.field && this.params.field === 'packageWeight' ? this.grossWeightSum + Number.parseFloat(this.weight) : ''
    },
    submit(){
      if(this.params.field === 'packageWeight'){
        this.$store.dispatch('statistics/calcPackageWeight', {
          weight: Number.parseFloat(this.grossWeightSum) + Number.parseFloat(this.weight),
          id: this.selected.id
        }).then(() => {
          this.$snackbar({text:'Вес обновлен', color:'green', top:false, right: false})
          this.close()
        }).catch(() => {
          this.$snackbar({text:'Ошибка', color:'red', top:false, right: false})
        })
      }
      this.$store.dispatch('statistics/calcWeightWithParam', {...this.params, weight:this.weight, id: this.selected.id }).then(() => {
        this.$snackbar({text:'Вес обновлен', color:'green', top:false, right: false})
        this.close()
      }).catch(() => {
        this.$snackbar({text:'Ошибка', color:'red', top:false, right: false})
      })

    },
    unset(){
      this.weight = null
    },
    close(){
      this.$emit('update:show', false)
    }
  }
}
</script>
